.image {
  overflow: hidden;
}

.image img {
  opacity: 0;
  transition: opacity 0.5s var(--bezier);
  transition-delay: 0.5s;
}
.image.in-view img {
  opacity: 1;
}
.image.image-second img {
  transition-delay: 0.75s;
}
.image.image-third img {
  transition-delay: 1s;
}
.image.image-forth img {
  transition-delay: 1.25s;
}

section {
  opacity: 0;
  transform: translate(20px, 0);
  transition:
    opacity var(--timing) var(--bezier),
    transform var(--timing) var(--bezier),
    background calc(var(--timing)*2) var(--bezier)
  ;
  transition-delay: var(--timing);
}

section.in-view {
  opacity: 1;
  transform: translate(0, 0);
}
