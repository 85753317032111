@custom-media --mq-380 (width <= 380px);
@custom-media --mq-730 (380px < width <= 730px);
@custom-media --mq-768 (730px < width <= 768px);
@custom-media --mq-nav-change (width <= 1023px);
@custom-media --mq-1024 (768px < width <= 1024px);
@custom-media --mq-1441 (1441px <= width < 1680px);
@custom-media --mq-1680 (width >= 1680px);

:root {

  /* Colour scheme */
  --brand-colour-light: #ffffff;
  --brand-colour-dark: #130f0b;
  --brand-colour-accent: #c9bb9f;
  --brand-colour-dark-lighter: #423F3C;
  --brand-colour-disabled: #cccccc;
  --brand-colour-neutral: #707070;

  /* Spacing */
  --main-spacing: 44px;
  --section-spacing: 156px;
  --gutter: 16px;

}

@media (--mq-730) {
  :root {

    /* Spacing */
    --main-spacing: 27px;
    --gutter: 12px;
    --section-spacing: 80px;

  }
}

@media (--mq-380) {
  :root {

    /* Spacing */
    --main-spacing: 24px;
    --gutter: 10px;
    --section-spacing: 80px;

  }
}

@media (--mq-768) {
  :root {

    --section-spacing: 132px;

  }
}

html {
  scroll-behavior: smooth;
}

::selection {
  color: var(--brand-colour-dark);
  background-color: var(--brand-colour-light);
}

body {
  background-color: var(--brand-colour-dark);
  height: auto;
  overflow-x: hidden;
}

img {
  width: 100%;
}
.mobile-nav {
  z-index: 10;
  display: none;
  background-color: var(--brand-colour-dark);
  justify-content:space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  border-top: 1px solid var(--brand-colour-neutral);
  /* height: 60px; */
}
.mobile-nav a {
  padding: 16px 32px;
}
.mobile-nav img {
  width: auto;
}

header {

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  padding: 20px 27px;
  /* flexbox is a modern css layout mode that gives us
  much more power over how elements are positioned */
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* z-index allows us to control the order of elements stacking
  on top of each other. it only really matters when using
  fixed/absolute positioning because elements get taken onto
  separate layers */
  z-index: 2;
}

header h1, .site-name img {
  width: 36px;
  font-size: 16px;
  margin: 0;
}

.nav-links a {
  font-size: 14px;
  letter-spacing: 0.05em;
}

.nav-projects {
  text-transform: uppercase;
}
.nav-projects a {
  margin-left: 24px;
  padding: 20px 0;
}
.nav-projects a.selected {
  background-image: url("img/active-project.svg");
  background-position: center top;
  background-repeat: no-repeat;
}

.link-tele::after {
  content: " |";
  color: #5A5A5A;
  margin: 0 16px;
}

.hero-image-wrap {
  position: absolute;
  right: 0;
  top:85px;
  width: 60%;
  z-index: -1;
  text-align: right;
}
.hero-text {
  margin-left: var(--main-spacing);
  margin-bottom: var(--section-spacing);
  width: 30%;
  z-index: 2;
  max-width: 380px;
}
.hero-text h1 {
  margin-left: -0.05em;
  margin-bottom: 0.2em;
}
.hero-text h1.project-titles {
    margin-bottom: 0.4em;
}

.hero-wrap {
  border-left: 2px solid #fff2;
  margin-left: var(--main-spacing);
  margin-top: 40vh;
  padding-bottom: 1px;
  min-height: 60vh;
}

#about {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row-reverse;
  padding: var(--main-spacing) 0 0 var(--main-spacing);
}

#about div {
  width: 50%;
}

.about-text {
  margin: 0 var(--main-spacing) 0 0;
  max-width: 600px;
}

.content-wrap {
  padding: var(--section-spacing) var(--main-spacing) var(--main-spacing) var(--main-spacing);
}
.content-project-wrap {
  padding: var(--main-spacing);
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--main-spacing) var(--gutter);
}

.image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gutter) var(--gutter);
}
.image-grid-item-large {
  grid-column: span 2;
}

.project-wrap {
  padding: var(--main-spacing);
}
.project-wrap.kitchen-hove.in-view {
  background-color: #1B2326;
}
.project-wrap.kitchen-exeter.in-view {
  background-color: #2F0027;
}
.project-wrap.ginger-pig.in-view {
  background-color: #1D1817;
}
.project-wrap.flint-house.in-view {
  background-color: #151C18;
}
.project-wrap.ginger-dog.in-view {
  background-color: #211715;
}

.project-images {
  grid-column: span 8;
}
.project-info {
  grid-column: span 4;
}
.project-info h2 {
  margin-bottom: 0;
  margin-left: -0.05em;
}
p.location {
  color: var(--brand-colour-light);
  margin-bottom: 0;
}
.vertically-centred {
  display: block;
  position: sticky;
  padding-left: var(--main-spacing);
  padding-bottom: var(--main-spacing);
  top: var(--section-spacing);
}
.link-to-projects {
  position: relative;
  margin-top: var(--main-spacing);
}
.link-to-projects a {
  position: absolute;
  top:50%;
  left: 50%;
  transform:translate(-50%, -50%);
  /* border: 0; */
  margin: 0;
}
.project-link-images .left,
.project-link-images .right {
  grid-column: span 3;
  object-fit: cover;
  height: 100%;
}
.project-link-images .middle {
  grid-column: span 6;
  /* height: 100%; */
  object-fit: cover;
}


.projects-links {
  margin-top: var(--main-spacing);
}

.projects-intro h3 {
  grid-column: 2 / span 6;
}
.view-projects-label {
  text-align: right;
  grid-column: 10 / span 2;
}
.projects-link {
  grid-column: span 5;
  border: 1px solid var(--brand-colour-neutral);
  transition: all 0.2s cubic-bezier(.58,.07,.36,.84);
}
.projects-link:focus {
  outline: 1px solid var(--brand-colour-accent);
}
.projects-link:hover {
  border: 1px solid var(--brand-colour-accent);
  background-color: var(--brand-colour-dark-lighter);
}
.projects-domestic {
  grid-column: 2 / span 5;
}

.project-info-wrap {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 14px var(--gutter);
}
.project-info-wrap h2 {
  margin: 0;
}
section.quote, section.instagram {
  margin: var(--section-spacing) 0;
  text-align: center;
}
section.quote.project-quotes {
  margin: 0;
}
.quote-wrap {
  grid-column: 2 / span 10;
  border: 1px solid var(--brand-colour-neutral);
  padding: var(--section-spacing);
}
.instagram a.instagram-link {
  padding: 24px;
  display: inline-block;
}
.instagram a h3 {
  border-bottom: 1px solid var(--brand-colour-dark);
  letter-spacing: 0.05em;
  transition: all 0.2s cubic-bezier(.58,.07,.36,.84);
}

.instagram a:hover h3 {
  border-bottom: 1px solid var(--brand-colour-neutral);
}

.instagram-images {
  margin-bottom: var(--main-spacing);
}

.instagram-images li {
  grid-column: span 3;
}

.project-enquiry {
  border: 1px solid var(--brand-colour-neutral);
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: stretch;
}
.project-enquiry h2 {
  margin-bottom: 0.5em;
}
.hands-image {
  background-image: image-set(
    "img/hands-small.jpg" 1x,
    "img/hands-large.jpg" 2x
  );
  background-size: cover;
  background-position: center center;
  width:50%;
}
.enquiry-text-wrap {
  width:50%;
}
.enquiry-text {
  margin: 4em;
  max-width: 400px;
}
.project-enquiry .contact-details {
  margin: 1em 0;
  font-size: 14px;
  letter-spacing: 0.05em;
}

.tele-links .landline::after {
  content: " |";
  color: #5A5A5A;
  margin: 0 16px;
}
.contact-details a {
  padding: 10px 0;
  display: inline-block;
}

.footer-wrap {
  border: 1px solid var(--brand-colour-neutral);
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: var(--main-spacing) 0;
  padding: 8em 8em;
  font-size: 12px;
  line-height: 2;
}
footer .site-name {
  flex: 2;
  order: 2;
  text-align: center;
}
footer .site-address, footer .social-links {
  flex: 1;
}
footer .site-address {
  order: 1;
}
footer .social-links {
  order: 3;
  text-align: right;
}
footer .social-links a {
  color: var(--brand-colour-accent);
}
footer .social-links a:hover {
  color: var(--brand-colour-light);
}

.site-colophon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-colophon, .site-colophon a {
  color: var(--brand-colour-neutral);
  font-size: 10px;
}
.site-colophon a {
  text-decoration: underline;
}

@media (--mq-380) {
  .projects-intro h3 {
    grid-column: 1 / span 12;
  }
  .link-email {
    font-size: 10px;
  }
  .projects-link {
    grid-column: span 12;
  }
  .projects-domestic {
    grid-column: 1 / span 12;
  }
  .view-projects-label {
    grid-column: 1 / span 12;
    text-align: left;
  }
  .nav-links a {
    font-size: 12px;
  }
  .hero-wrap {
    border-left: 0;
    margin-left: 44px;
    margin-left: 0;
    margin-top: var(--main-spacing);
    min-height: inherit;
  }
  .hero-text {
    width:auto;
    margin: 0 var(--main-spacing) var(--section-spacing) var(--main-spacing) ;
    max-width: 260px;
  }
  .mobile-nav a {
    padding: 10px 32px;
  }
  .hero-image-wrap {
    position: initial;
    right: 0;
    top: 0;
    padding-top: 85px;
    padding-left: var(--main-spacing);
    width: 100%;
  }
  #about {
    display: block;
    padding: var(--main-spacing) var(--main-spacing) 0 0;
  }
  #about div {
    width: 100%;
  }
  .about-text {
    margin: 0;
    padding: var(--main-spacing) 0 var(--main-spacing) var(--main-spacing);
  }
  .btn {
    width: 100%;
    text-align: center;
  }
  .label {
    font-size: 10px;
  }

  .quote-wrap {
    grid-column: 1/span 12;
    padding: var(--section-spacing) var(--main-spacing);
  }

  .instagram a.instagram-link {
    padding: 4px;
  }
  .instagram-images li {
    grid-column: span 12;
  }
  .instagram-images.grid {
    grid-row-gap: var(--main-spacing);
  }

  .footer-wrap {
    display: block;
    padding: 2em;
    text-align: center;
  }

  footer .site-name {
    /* max-width: 140px; */
    margin: var(--main-spacing) auto;
  }

  footer .social-links {
    text-align: center;
  }

  .site-colophon {
    display: block;
    text-align: center;
  }

  .project-enquiry {
    display: block;
  }

  .hands-image {
    width: 100%;
    height: 160px;
  }
  .enquiry-text-wrap {
    width:100%;
  }
  .enquiry-text {
    margin: var(--main-spacing);
    max-width: 400px;
  }

  .project-info, .project-images {
    grid-column: span 12;
  }
  .vertically-centred {
    position: inherit;
    padding-left: 0;
    padding-bottom: var(--main-spacing);
    padding-top: var(--main-spacing);
  }

  .link-to-projects {
    margin-top: var(--section-spacing);
  }

  .link-to-projects a {
    position: inherit;
    border: 1px solid white;
    width: calc(100% - var(--main-spacing)*2);
    margin: calc(var(--main-spacing)*2) auto;
    padding: 18px;
  }

  .content-project-wrap {
    padding: var(--main-spacing) var(--main-spacing) var(--section-spacing) var(--main-spacing);
  }

}
@media (--mq-730) {

  .link-to-projects a {
    position: inherit;
    border: 1px solid white;
    width: calc(100% - var(--main-spacing)*2);
    margin: calc(var(--main-spacing)/2) auto;
    padding: 18px;
  }

  .projects-intro h3 {
    grid-column: 1 / span 12;
  }
  .projects-link {
    grid-column: span 12;
  }
  .projects-domestic {
    grid-column: 1 / span 12;
  }
  .view-projects-label {
    grid-column: 1 / span 12;
    text-align: left;
  }
  .nav-links a {
    font-size: 12px;
  }
  .hero-wrap {
    border-left: 0;
    margin-left: 44px;
    margin-left: 0;
    margin-top: var(--main-spacing);
    min-height: inherit;
  }
  .hero-text {
    width:auto;
    max-width: 260px;
    margin: 0 var(--main-spacing) var(--section-spacing) var(--main-spacing) ;
  }
  .mobile-nav a {
    padding: 10px 32px;
  }
  .hero-image-wrap {
    position: initial;
    right: 0;
    top: 0;
    padding-top: 85px;
    padding-left: var(--main-spacing);
    width: 100%;
  }
  #about {
    display: block;
    padding: var(--main-spacing) var(--main-spacing) 0 0;
  }
  #about div {
    width: 100%;
  }
  .about-text {
    margin: 0;
    padding: var(--main-spacing) 0 var(--main-spacing) var(--main-spacing);
  }
  .btn {
    width: 100%;
    text-align: center;
  }
  .label {
    font-size: 10px;
  }

  .quote-wrap {
    grid-column: 1/span 12;
    padding: var(--section-spacing) var(--main-spacing);
  }

  .instagram a.instagram-link {
    padding: 4px;
  }
  .instagram-images li {
    grid-column: span 12;
  }
  .instagram-images.grid {
    grid-row-gap: var(--main-spacing);
  }

  .footer-wrap {
    display: block;
    padding: var(--section-spacing) 2em;
    text-align: center;
  }

  footer .site-name {
    /* max-width: 140px; */
    margin: var(--main-spacing) auto;
  }

  footer .social-links {
    text-align: center;
  }

  .site-colophon {
    display: block;
    text-align: center;
  }

  .project-enquiry {
    display: block;
  }

  .hands-image {
    width: 100%;
    height: 40vh;
  }
  .enquiry-text-wrap {
    width:100%;
  }
  .enquiry-text {
    margin: var(--main-spacing);
    max-width: 400px;
  }

  .project-info, .project-images {
    grid-column: span 12;
  }
  .vertically-centred {
    position: inherit;
    padding-left: 0;
    padding-bottom: var(--main-spacing);
    padding-top: var(--main-spacing);
  }
  .content-project-wrap {
    padding: var(--main-spacing) var(--main-spacing) var(--section-spacing) var(--main-spacing);
  }

}
@media (--mq-768) {

  .footer-wrap {
    padding: 4em;
  }

  footer .site-name {
    flex: 1;
  }
  .hero-image-wrap {
    position: initial;
    right: 0;
    top: 0;
    padding-top: 85px;
    padding-left: var(--main-spacing);
    width: 100%;
  }
  body .hero-wrap {
    margin-top: var(--main-spacing);
    min-height: inherit;
  }
  .hero-text {
    width: 40%;
  }
  .quote-wrap {
    grid-column: 1 / span 12;
    padding: calc(var(--section-spacing) / 2);
  }
  .projects-intro h3 {
    grid-column: 1 / span 10;
  }
  .projects-link {
    grid-column: span 6;
  }
  .projects-domestic {
    grid-column: 1 / span 6;
  }
  .view-projects-label {
    grid-column: 1 / span 6;
    text-align: left;
  }
  .instagram-images li {
    grid-column: span 4;
  }
  .instagram-images li.first,
  .instagram-images li.third {
    grid-column: 3 / span 4;
  }

}
@media (--mq-nav-change) {

  .project-enquiry .contact-details, header .contact-details {
    display: none;
  }
  .mobile-nav {
    display: flex;
  }
  .content-wrap {
    padding: var(--section-spacing) var(--main-spacing) 100px var(--main-spacing);
  }
  footer .site-name h4 {
    max-width: 140px;
    margin: 1em auto;
  }

}
@media (--mq-1024) {
  .hero-wrap {
    margin-top: 48vh;
    min-height: 52vh;
  }
  .hero-image-wrap {
    width: 70%;
  }
  .quote-wrap {
    grid-column: 1 / span 12;
  }
  .projects-intro h3 {
    grid-column: 1/span 9;
  }
  .projects-link {
    grid-column: span 6;
  }
  .projects-domestic {
    grid-column: 1 / span 6;
  }
  .view-projects-label {
    grid-column: 10 / span 4;
  }

}
@media (--mq-1441) {
  .quote-wrap {
    grid-column: 2 / span 10;
  }
  .projects-intro h3 {
    grid-column: 1/span 6;
  }
  .projects-link {
    grid-column: span 6;
  }
  .projects-domestic {
    grid-column: 1 / span 6;
  }
  .view-projects-label {
    grid-column: 11 / span 2;
  }
  .content-wrap {
    padding: var(--section-spacing) var(--section-spacing) var(--main-spacing) var(--section-spacing);
  }
  #about {
    padding: var(--section-spacing) 0 0 var(--main-spacing);
  }
  .enquiry-text {
    margin: 8em;
  }
  .hero-text {
    max-width: inherit;
    width: 32%;
  }
  .hero-text p {
    max-width: 380px;
  }
}
@media (--mq-1680) {
  .content-wrap {
    padding: var(--section-spacing) 0 var(--main-spacing) 0;
    margin: 0 auto;
    max-width: 1440px;
  }
  #about {
    padding: var(--section-spacing) 0 0 var(--main-spacing);
  }
  .hero-text {
    max-width: inherit;
  }
  .hero-text p {
    max-width: 380px;
  }
}
