@custom-media --mq-380 (width <= 380px);
@custom-media --mq-480 (380px < width <= 480px);
@custom-media --mq-768 (480px < width <= 768px);
@custom-media --mq-1024 (768px < width <= 1024px);
@custom-media --mq-1441 (1441px <= width < 1680px);
@custom-media --mq-1680 (width >= 1680px);

:root {

  --heading-font: 'Work Sans', sans-serif;
  --body-font: 'Work Sans', sans-serif;

  --text-dark: #130f0b;
  --text-light: #ffffff;
  --text-light-secondary: #c9bb9f;

  --body-size: 16px;

  --h1-size: 81px;
  --h2-size: 38px;
  --h3-size: 32px;
  --h4-size: 32px;
}

@media (--mq-1680) {
  :root {
    --h1-size: 81px;
    --h2-size: 44px;
    --h3-size: 32px;
    --h4-size: 32px;
  }
}
@media (--mq-1441) {
  :root {
    --h1-size: 81px;
    --h2-size: 44px;
    --h3-size: 32px;
    --h4-size: 32px;
  }
}
@media (--mq-1024) {
  :root {
    --h1-size: 72px;
    --h2-size: 32px;
    --h3-size: 32px;
    --h4-size: 32px;
  }
}
@media (--mq-768) {
  :root {
    --h1-size: 62px;
    --h2-size: 26px;
    --h3-size: 22px;
    --h4-size: 22px;

    --body-size: 14px;
  }
}
@media (--mq-480) {
  :root {
    --h1-size: 56px;
    --h2-size: 22px;
    --h3-size: 22px;
    --h4-size: 22px;

    --body-size: 14px;
  }
}
@media (--mq-380) {
  :root {
    --h1-size: 38px;
    --h2-size: 20px;
    --h3-size: 18px;
    --h4-size: 18px;

    --body-size: 14px;
  }
}


body {
  font-family: var(--body-font);
  font-size: var(--body-size);
  line-height: 1.5;
  font-weight: 400;
  color: var(--text-light-secondary);
}

h1, h2, h3, h4, blockquote {
  font-family: var(--heading-font);
  font-weight: 300;
  color: var(--text-light);
  line-height: 1.3;
  margin-bottom: 1em;
  letter-spacing: 0.02em;
}
blockquote.project-quotes {
  margin-bottom: 0;
}

h1 {
  font-size: var(--h1-size);
  line-height: 1;
  letter-spacing: 0.05em;
}
h2, blockquote {
  font-size: var(--h2-size);
}
.hero-text h1.project-titles {
  font-size: var(--h2-size);
}
h3 {
  font-size: var(--h3-size);
}

a {
  color: var(--text-light);
  text-decoration: none;
  transition: color 0.2s cubic-bezier(.58,.07,.36,.84);
}

a:hover {
  color: var(--text-light-secondary);
}

p {
  margin-bottom: 1em;
}

.label, .btn {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 12px;
  color: var(--text-light-secondary);
  font-weight: 500;
}

.btn {
  background-color: var(--text-light-secondary);
  padding: 18px 32px;
  color: var(--text-dark);
  display: inline-block;
  margin: 1em 0;
  transition: all 0.2s cubic-bezier(.58,.07,.36,.84);
  position: relative;
}
.btn-secondary {
  background-color: var(--text-dark);
  color: var(--text-light);
  border: 1px solid var(--text-light);
  padding: 18px 64px;
}
.btn:focus {
  background-color: var(--text-light);
  color: var(--text-dark);
  outline: 4px solid var(--text-light-secondary);
}
.btn:hover {
  /* background-color: var(--text-light); */
  color: var(--text-dark);
}

a.btn span{
  position: relative;
  z-index: 2;
}
a.btn:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--text-light);
  transition: all 0.2s cubic-bezier(.58,.07,.36,.84);
}
a.btn:hover:after{
  width: 100%;
}

blockquote {
  quotes: "\201C""\201D""\2018""\2019";
  /* display: inline-block; */
}

blockquote:before {
  color: #343434;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.1em;
  vertical-align: -0.4em;
}
blockquote:after {
  color: #343434;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-left: 0.1em;
  vertical-align: -0.6em;
}

.site-name h4 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 1em 0 1em 0;
}
