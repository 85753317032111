.headroom {
    will-change: transform;
    transition: transform 300ms cubic-bezier(.58,.07,.36,.84);
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
    transform: translateY(-100%);
}
