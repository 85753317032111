:root {
  --timing: 0.5s;
  --bezier: cubic-bezier(.58,.07,.36,.84);
}

main {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
}

.barba-container {
  position: absolute;
  width: 100%;
  transform-origin: top right;
}

.fade-leave-active,
.fade-enter-active,
.fade-once-active {
  transition:
    opacity var(--timing) var(--bezier),
    transform var(--timing) var(--bezier)
  ;
}

.fade-leave {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-leave-to {
  opacity: 0;
  transform: translate(20px, 0);
}

.fade-enter,
.fade-once {
  opacity: 0;
  transform: translate(20px, 0);
}

.fade-enter-to,
.fade-once-to {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translate(0, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

header h1,
header .contact-details,
header .nav-projects {
  animation: fade 0.2s var(--bezier) both;
}

header h1 {
  animation-delay: 0.2s;
}
header .contact-details {
  animation-delay: 0.4s;
}
header .nav-projects {
  animation-delay: 0.6s;
}

@media (--mq-380) {
  .barba-container {
    overflow: hidden;
  }
}
@media (--mq-730) {
  .barba-container {
    overflow: hidden;
  }
}
